import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/layout";
import ChainInfo from "../../../views/chain-info";
import { Seo } from "../../../components/seo";
import { getBanner, reduceValue } from "../../../components/utils";
import axios from "axios";
import * as mainStyles from "../../../styles/index.module.scss";
import Spinner from "../../../components/spinner";
import { BsCheckCircleFill, BsExclamationCircleFill, BsFillExclamationTriangleFill } from "react-icons/bs";

export const Head = ({ location, data: { testChainsJson: chain, allFile: files } }) => (
  <Seo
    title={`${chain.name} Testnet 2.0 Leaderboard`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
);

const filterVals = [ "node0", "node1", "node2", "node3", "Uptick" ];

const Chain = props => {
  const { testChainsJson, allFile } = props.data;
  const [ delegatorAddress, setDelegatorAddress ] = React.useState("uptick1ncn0k65x3esuzxztzymd0s0kwhun7wxnrcc9mw");
  const [ delegations, setDelegations ] = React.useState([]);
  const [ filter, setFilter ] = React.useState("");

  React.useEffect(
    () => {
      axios
        .get(`https://testnet.uptick.api.explorers.guru/api/v1/accounts/${delegatorAddress}/delegations`)
        .then(response => response.data)
        .then(data => {
          const sortedData = data
            .filter(delegation => !filterVals.includes(delegation.validator.moniker))
            .sort((a, b) => b.tokens.amount - a.tokens.amount);
          setDelegations(sortedData);
        })
        .catch(e => console.error(e));
    },
    [ delegatorAddress ]
  );
  return (
    <Layout>
      <ChainInfo chain={testChainsJson} data={allFile.edges} testnet />
      <h2>Leaderboard</h2>
      <label htmlFor="delegatorAddress">Delegator address</label>
      <input
        id="delegatorAddress"
        type="text"
        value={delegatorAddress}
        onChange={e => setDelegatorAddress(e.target.value)}
        placeholder="uptick1ncn0k65x3esuzxztzymd0s0kwhun7wxnrcc9mw"
        style={{ width: "calc(100% - 2rem)" }}
      />
      <label htmlFor="filter">Find validator</label>
      <input
        id="filter"
        type="text"
        value={filter}
        onChange={e => setFilter(e.target.value)}
        placeholder="My moniker"
        style={{ width: "calc(100% - 2rem)" }}
      />
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Validator</th>
            <th>Delegation</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {delegations.length ? (
            delegations.map((delegation, i) => (
              <tr
                key={i}
                style={{ display: delegation.validator.moniker.match(new RegExp(filter, "i")) ? "table-row" : "none" }}
              >
                <td>#{i + 1}</td>
                <td>
                  <a
                    href={`https://testnet.uptick.explorers.guru/validator/${delegation.validator.operatorAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={delegation.validator.avatar}
                      alt={`${delegation.validator.moniker} avatar`}
                      className={mainStyles.validatorAvatar}
                    />
                    {delegation.validator.moniker}
                  </a>
                </td>
                <td>{reduceValue(delegation.tokens.amount, delegation.tokens.denom)} UPTICK</td>
                <td>
                  {delegation.status === "Active" ? (
                    <BsCheckCircleFill size={24} className={mainStyles.statusActive} title={delegation.status} />
                  ) : (
                    <BsFillExclamationTriangleFill
                      size={24}
                      className={mainStyles.statusInactive}
                      title={delegation.status}
                    />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <Spinner /> Loading leaderboard...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Layout>
  );
};

export default Chain;

export const query = graphql`
  query {
    testChainsJson(key: { eq: "uptick" }) {
      id
      about
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;
